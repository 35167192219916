import axios, { AxiosError } from "axios";

import { WBAPI } from "@/api/WBAPI";
import { SnapshotInfo } from "@/model/api";

export interface FreeSnapshotProps {
  error: string | undefined;
  sk: string | undefined;
}

export const HandleGetFreeSnapshot = async (
  companyId: string,
  isAuthenticated: boolean = false
): Promise<FreeSnapshotProps> => {
  let snapshotKey: string | undefined;
  let errMessage: string | undefined;
  const api = isAuthenticated
    ? WBAPI.FREE_SNAPSHOT_ENDPOINT_MEMBER
    : WBAPI.FREE_SNAPSHOT_ENDPOINT;
  await axios
    .get(`${api}/${companyId}`)
    .then((res: any) => res.data)
    .then(async (res: SnapshotInfo) => {
      await axios
        .post(WBAPI.GET_OR_CREATE_SNAPSHOT_SHARE_KEY, {
          id: res.id,
          product_key: res.product_key,
        })
        .then((res: any) => res.data)
        .then((sk: string) => {
          snapshotKey = sk;
        })
        .catch((err: AxiosError) => {
          errMessage = err.request?.responseText;
        });
    })
    .catch((err: AxiosError) => {
      errMessage = err.response?.data ?? "HandleGetFreeSnapshot failed";
    });

  return {
    error: errMessage,
    sk: snapshotKey,
  };
};

export const formatTextToSlug = (text: string): string => {
  // Convert text to lowercase
  let slug = text?.toLowerCase();

  // Replace spaces with hyphens
  slug = slug?.replace(/\s+/g, "-");

  // Remove special characters
  slug = slug?.replace(/[^\w-]+/g, "");

  return slug;
};

export const extractTickerFromArticleSlug = (slug: string): string | null => {
  // Split the string by comma (,)
  const parts = slug.split(",");

  // If the string has at least one part after splitting
  if (parts.length > 0) {
    // Return the first part (the first word)
    return parts[0].trim(); // Trim any leading or trailing whitespace
  }

  // If the string is empty or does not contain any word, return null
  return null;
};

export const extractSlugFromArticleSlug = (slug: string): string | null => {
  // Split the string by comma (,)
  const parts = slug.split(",");

  // If the string has at least one part after splitting
  if (parts.length > 0) {
    // Return the first part (the first word)
    return parts[parts.length - 1].trim(); // Trim any leading or trailing whitespace
  }

  // If the string is empty or does not contain any word, return null
  return null;
};
