import Image from "next/image";
import { useEffect, useState } from "react";

import { NarrativeSummary } from "@/model/api";
import { getArticleImageUrl } from "@/util/article";

interface Props {
  narrative: NarrativeSummary;
  ticker: string;
}

export const ArticleImage = ({ ticker, narrative }: Props) => {
  const mainSrc = getArticleImageUrl(narrative);
  const fallbackSrc = "/img/thumbnails/narrative-thumbnail-fallback.jpg";
  const [imgSrc, setImgSrc] = useState(mainSrc);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setImgSrc(mainSrc);
  }, [mainSrc]);

  return (
    <div className="relative h-full w-full">
      <Image
        src={imgSrc}
        onError={() => setImgSrc(fallbackSrc)}
        onLoadingComplete={() => {
          setLoading(false);
        }}
        alt={`article image for ${ticker}`}
        className="rounded-lg"
        priority
        data-testid="test-article-img"
        fill
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
        style={{
          objectFit: "cover",
        }}
      />
      {loading && (
        <div className="animate-pulse absolute inset-0 bg-gray-200 rounded-lg" />
      )}
    </div>
  );
};
